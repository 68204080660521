<template>
  <div>
    <div v-if="isLoading" class="text-center pt-3">
      <b-spinner label="Loading..." />
    </div>

    <b-card-group
      v-else-if="survey.completed"
      deck
      class="justify-content-center"
    >
      <b-card no-body style="max-width: 560px" class="mb-3">
        <b-card-body>
          <b-card-title>Submitted Answers</b-card-title>
        </b-card-body>

        <b-list-group flush>
          <b-list-group-item v-for="detail in survey.details" :key="detail.key">
            <strong>{{ detail.title }}</strong> {{ detail.value }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-card-group>

    <div
      v-else
      :data-prefill="prefill"
      data-paperform-id="complete-charter-survey"
    />
  </div>
</template>

<script>
import {
  BSpinner,
  BCard,
  BCardGroup,
  BCardBody,
  BCardTitle,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import axios from "@/libs/axios";
import { toastFailure } from "@/libs/toastification";

export default {
  components: {
    BSpinner,
    BCard,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      isLoading: true,
      prefill: "",
      survey: {
        completed: 0,
        details: null,
      },
    };
  },
  mounted() {
    const { bookingId } = { ...this.$route.params };
    this.prefill = `charter_booking_id=${bookingId}`;
    axios
      .get(`api/charter-survey/${bookingId}`)
      .then((response) => {
        const data = response.data.data;
        this.survey = { ...data };
        this.prefill += `
          &charter_booking_boat=${data.boat}
          &charter_booking_size=${data.size}
          &charter_booking_date=${data.date}
        `;
        if (data.name) this.prefill += `&charter_booking_name=${data.name}`;
        this.surveyLaunch();
        this.isLoading = false;
      })
      .catch(() => {
        toastFailure("Something went wrong.");
      });
  },
  methods: {
    surveyLaunch() {
      const script = document.createElement("script");
      script.src = "https://paperform.co/__embed.min.js";
      document.body.appendChild(script);
    },
  },
};
</script>

<style>
</style>
